<template>
  <article class="pad-t-0">
    <!-- Spinner loading -->
    <div class="bb-spinner" v-show="loading">
      <div class="bb-spiner__content">
        <div class="bb-spinner__dots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="bb-container-all">
      <div class="alert alert--primary" v-show="showAlert">
        <div class="alert__content">
          Tienes un plazo de
          {{ formatReservedAt($route.query.reservedAt) }} días para comprar este producto.
          De lo contrario, volverá a estar disponible para los demás usuarios.
        </div>
        <button class="alert__close" aria-label="Cerrar" @click="closeAlert = true">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.6979 6L12 10.3021L10.3021 12L6 7.6979L1.6979 12L0 10.3021L4.3021 6L0 1.6979L1.6979 0L6 4.3021L10.3021 0L12 1.6979L7.6979 6Z"
              fill="#004085"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="bb-container">
      <router-link :to="'/guest/' + this.$route.params.id">
        <div class="back">
          <img
            src="../../assets/img/ico/ico-back.svg"
            loading="lazy"
            alt="Volver"
            class="back__arrow"
          />
          <span class="back__back">
            <span class="back__name">{{ $t("guest.returnToGuestList") }} </span>
          </span>
        </div>
      </router-link>

      <div class="g-regalo">
        <h2 class="g-regalo__vamos">{{ $t("guest.letsDoAPresent") }}</h2>

        <div class="g-regalo__cols">
          <div class="g-regalo__left">
            <ShareProduct :product="product" :listId="myList.id" />
            <ListProductCarousel
              v-if="showCarrousel"
              :photos="photos"
              :dots="false"
              :arrows="true"
            ></ListProductCarousel>
          </div>
          <div class="g-regalo__right">
            <div class="regalo-card">
              <h1 class="regalo-card__titulo">
                {{ $utils.truncate(product.name, 85) }}
              </h1>
              <div class="regalo-card__prices" v-if="contributionEnabled && contributionsEnabledForProductPrice(product.price, myList)">{{ textContribution }}</div>
              <div class="regalo-card__prices" v-else>{{ textPrice }}</div>

              <div class="regalo-card__section" v-if="userComments">
                <h5 class="regalo-card__section-title">
                  Comentarios de {{ myList.ownerName }}
                </h5>
                <div class="regalo-card__comments" v-html="userComments" />
              </div>
              <div class="regalo-card__owner" v-if="retailerIsHelloBB">
                <img
                  v-if="getImgUrl === '#'"
                  src="../../assets/images/embarazada.jpg"
                  width="108"
                  height="108"
                  srcset="
                    ../../assets/images/embarazada-p-500.jpeg   500w,
                    ../../assets/images/embarazada-p-800.jpeg   800w,
                    ../../assets/images/embarazada-p-1080.jpeg 1080w,
                    ../../assets/images/embarazada.jpg         1314w
                  "
                  sizes="(max-width: 479px) 100vw, 200px"
                  alt
                />
                <img
                  v-else
                  :src="getImgUrl"
                  width="108"
                  height="108"
                  sizes="(max-width: 479px) 100vw, 200px"
                  alt
                />
                <span> ¡{{ myList.ownerName }} ha elegido este modelo en HelloBB! </span>
              </div>
              <div class="regalo-card__section" v-if="retailerIsHelloBB">
                <ul class="ecom-add-tips">
                  <li class="ecom-add-tips__item">Recíbelo en 24/48h.</li>
                  <li class="ecom-add-tips__item">
                    Envíos gratis para pedidos superiores a 50€.
                  </li>
                  <!--<li class="ecom-add-tips__item">
                    Devolución <a href="#">fácil</a> para
                    {{ myList.ownerName }} si cambia de idea.
                  </li>-->
                  <li class="ecom-add-tips__item">
                    Devolución <a href="/devoluciones">fácil</a> para
                    {{ myList.ownerName }} si cambia de idea.
                  </li>
                  <li class="ecom-add-tips__item">
                    {{ myList.ownerName }}  acumulará un 5% de <a href="/programa-de-recompensas">lo que gastes</a>.
                  </li>
                </ul>
              </div>
              <div class="regalo-card__section regalo-card__section--last" v-if="contributionEnabled && contributionsEnabledForProductPrice(product.price, myList)">
                <ListProductRegalar
                  :product="product"
                  :list="list"
                  @error="setErrorMessageForModal"
                ></ListProductRegalar>
              </div>
              <div class="regalo-card__section regalo-card__section--last" v-else>
                <ListProductSelectShop
                  v-if="!loading"
                  :product="product"
                  :list="myList"
                  @update="getList()"
                  @error="setErrorMessageForModal"
                ></ListProductSelectShop>
              </div>
            </div>

            <div class="g-regalo__comprado" v-if="!alreadyBought && SoldByHelloBB != 'Active' && !contributionEnabled && !contributionsEnabledForProductPrice(product.price, myList)">
              <label for="comprado" @click="openAlreadyBought">
                <input
                  type="checkbox"
                  id="comprado"
                  v-model="alreadyBought"
                  :disabled="alreadyBought"
                />
                {{ $t("generic.setAsPurchased") }}
              </label>
            </div>

            <div
              class="g-regalo__retailers"
              v-if="retailerIsHelloBB && moreVendorsBesidesHelloBB"
            >
              <div class="regalo-retailers">
                <div class="regalo-retailers__title">También lo puedes encontrar en:</div>
                <ListProductShops
                  v-if="!loading"
                  :product="product"
                  :list="myList"
                  @update="getList()"
                  @error="setErrorMessageForModal"
                />
              </div>
            </div>

            <!-- <div class="g-regalo__desc">
              {{ myList.ownerName }} ha escogido este producto. Ten en cuenta
              sus comentarios y las tiendas que ha escogido para acertar seguro.
            </div> -->

            <div
              class="g-regalo__msg"
              v-show="
                this.product.userComments != '' && this.product.userComments != null
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!--    ALREADY BOUGHT MODAL-->
    <transition name="bbFade" @after-enter="showAlreadyBoughtContent = true">
      <div
        class="modal"
        v-if="showAlreadyBought"
        @click="showAlreadyBoughtContent = false"
      >
        <transition name="bbUp" @after-leave="closeAlreadyBought()">
          <div v-show="showAlreadyBoughtContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back"></div>
                <div class="modal__close">
                  <img
                    src="../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showAlreadyBoughtContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="flux-regalo">
                  <div class="flux-regalo__title">{{ $t("guest.doYouPurchased") }}</div>

                  <div class="flux-regalo__msg">
                    {{ $t("guest.setAsPurchasedOnlyIf") }}
                  </div>
                  <div class="flux-regalo__msg flux-regalo__msg--small">
                    <img src="../../assets/img/ico/ico-question.svg" alt="" />
                    {{ $t("guest.setAsPurchasedAdv", { name: myList.ownerName }) }}
                  </div>

                  <div class="flux-regalo__actions">
                    <loading-button v-if="loadingMarcarComoRegalado" />
                    <a
                      v-else
                      href="#"
                      class="button button--primary"
                      @click="marcarComoRegalado()"
                      >{{ $t("guest.setAsPurchasedButton") }}</a
                    >
                  </div>
                  <div class="flux-regalo__informacion">
                    <div
                      class="info-compra"
                      v-if="
                        typeof myList.address === 'string' &&
                        myList.address.trim().length > 0
                      "
                    >
                      <div class="info-compra__title">{{ $t("guest.infoForPurchasing") }}</div>
                      <div class="info-compra__cols">
                        <div class="info-compra__col">
                          <h3 class="info-compra__col-title">
                            {{ $t("guest.addressOf", { name:myList.ownerName }) }}
                          </h3>
                          <div class="info-compra__col-content">
                            <list-owner-address :list="list"></list-owner-address>
                          </div>
                        </div>
                        <div class="info-compra__col"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <modal-dialog
      :show="showErrorModal"
      @close="errorMessageForModal = ''"
      target-class="modal__card--mini"
    >
      <section class="card-error">
        <!--div class="card-error__ico">
          <img
            src="../../assets/img/ico/ico-delete-circle.svg"
            alt="Error"
          />
        </div-->
        <h3 class="card-error__header">{{ $t("productPage.errors.notAvailableTitle") }}</h3>
        <div class="card-error__content">
          {{ errorMessageForModal }}
        </div>
        <div class="card-filtros__actions">
          <router-link
            class="button button--primary button--block"
            :to="'/guest/' + this.$route.params.id"
          >
          {{ $t("productPage.errors.buttonChooseAnother") }}
          </router-link>
        </div>
      </section>
    </modal-dialog>
  </article>
</template>
<script>
import ListProductSelectShop from "./components/ListProductSelectShop.vue";
import ListProductShops from "./components/ListProductShops.vue";
import { mapGetters } from "vuex";
import ListProductCarousel from "./components/ListProductCarousel";
import ListOwnerAddress from "./components/ListOwnerAddress";
import LoadingButton from "../../components/LoadingButton";
import MarcarComoRegalado from "./components/mixins/MarcarComoRegalado";
import ContributionsEnabledForProduct from "../../Mixins/ContributionsEnabledForProduct";
import ShareProduct from "../../components/ShareProduct";
import ListProductRegalar from "./components/ListProductRegalar";
import ModalDialog from "../../components/ui/ModalDialog";

export default {
  name: "ListProduct",
  mixins: [
    MarcarComoRegalado,
    ContributionsEnabledForProduct,
  ],
  metaInfo() {
    return {
      title: this.$cutText(this.product.name, 70, "...") + " | HelloBB",
      meta: [
        { name: "robots", content: "noindex" },
        { property: "og:title", content: this.product.name },
        { property: "og:site_name", content: "HelloBB" },
        { property: "og:type", content: "article" },
        { property: "og:image", content: this.product.photo },
        { property: "og:locale", content: "es_ES" },
        {
          property: "og:description",
          content: this.list.ownerName + " ha escogido este producto en HelloBB!",
        },
        { property: "og:url", content: process.env.APP_URL.substring(0, process.env.APP_URL.length - 1) + this.$route.path },
      ],
    };
  },
  components: {
    ListProductSelectShop,
    ListProductCarousel,
    ListProductShops,
    ShareProduct,
    "list-owner-address": ListOwnerAddress,
    "loading-button": LoadingButton,
    ListProductRegalar,
    "modal-dialog": ModalDialog,
  },
  data() {
    return {
      retailer: null,
      loading: true,
      showCarrousel: false,
      closeAlert: false,
      showAlreadyBought: false,
      showAlreadyBoughtContent: false,
      alreadyBought: false,
      listAvailable: false,
      errorMessageForModal: "",
    };
  },
  computed: {
    ...mapGetters({
      myList: "myList",
      myListLanguage: "myListLanguage",
      currency: "myListCurrency",
    }),
    showErrorModal() {
      return (this.errorMessageForModal || "").trim() !== "";
    },
    retailerPrice() {
      return this.product.productRetailers ? this.product.productRetailers[0].price : 0;
    },
    list() {
      return this.myList;
    },
    userComments() {
      return this.product.userComments != null ? this.product.userComments : "";
    },
    product() {
      return (
        this.myList.products?.find((product) => {
          if (product.isFree)
            return (
              parseInt(product.id) === parseInt(this.$route.params.productId) &&
              product.isFree === (this.$route.params.productType === "free")
            );
          else
            return (
              parseInt(product.listProductId) ===
                parseInt(this.$route.params.productId) &&
              product.isFree === (this.$route.params.productType === "free")
            );
        }) || {}
      ); // Product is always required -> defensive programming to avoid Javascript errors when product is not found by find
    },
    photos() {
      return this.product.photos?.length > 0
        ? this.product.photos
        : [{ path: this.product.photo }];
    },
    showAlert() {
      if (this.closeAlert) return false;
      return this.product.status === 3 && this.$route.query.reservedAt;
    },
    productRetailers() {
      if (this.product.isFree) {
        return [
          {
            name: this.$utils.capitalize(this.product.retailerName),
            price: this.product.price,
          },
        ];
      }
      return this.product.productRetailers;
    },
    productRetailersName() {
      return this.product.productRetailers?.map(function (item) {
        return item["name"];
      });
    },
    retailerIsHelloBB() {
      return this.retailer?.name === "HelloBB" || this.product.shopifyId;
    },
    SoldByHelloBB() {
      if (this.retailerIsHelloBB) return "Active";
      return "No";
    },
    getImgUrl() {
      if (this.myList.photo == "" || this.myList.photo == null) {
        return "#";
      }
      return process.env.URL_IMG_USER + this.myList.photo;
    },
    textPrice() {
      if (this.product.isFree) {
        return this.$utils.formatMoney(this.product.price, this.currency);
      }
      if (this.retailerIsHelloBB) {
        return this.$utils.formatMoney(this.retailerPrice, this.currency);
      }
      var priceArray = this.product.productRetailers?.map(function (item) {
        return item["price"];
      });
      var minValue = Math.min.apply(Math, priceArray);
      var maxValue = Math.max.apply(Math, priceArray);
      if (minValue == maxValue) return this.$utils.formatMoney(this.retailerPrice, this.currency);
      return (
        this.$utils.formatMoney(minValue, this.currency) + " a " + this.$utils.formatMoney(maxValue, this.currency)
      );
    },
    textContribution() {
      if (this.product.contributionStatus == 1 && this.product.contributionAmount > 0) {
        return "Aportado hasta ahora: " + this.$utils.formatMoney(this.product.contributionAmount) + " de " + this.$utils.formatMoney(this.product.contributionGoalAmount);
      } else {
        return this.$utils.formatMoney(this.product.price)
      }
    },
    moreVendorsBesidesHelloBB() {
      if (this.retailerIsHelloBB && this.product.productRetailers.length == 1)
        return false;
      return true;
    },
    contributionEnabled() {
      return this.list.stripeAccountId &&
            !this.retailerIsHelloBB &&
            this.product.contributionStatus == 1
    },
  },
  methods: {
    closeAlreadyBought() {
      this.showAlreadyBought = false;
      this.alreadyBought = false;
      document.body.classList.remove("modal-open");
      this.$emit("close");
    },
    openAlreadyBought() {
      document.body.classList.add("modal-open");
      this.showAlreadyBought = true;
    },
    formatReservedAt() {
      return "3";
    },
    async getList(server = false, id = null) {
      let auth = false;
      
      //get from localStorage if the guest is authorized
      try {
        if (server == false) {
          if (localStorage.getItem("guest_" + this.$route.params.id) == "true") {
            auth = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
      return await this.$store.dispatch("getListGuest", {
        id: id ? id : this.$route.params.id,
        server,
        auth
      });
    },
    async getListFromServer() {
      return await this.getList(true);
    },
    setErrorMessageForModal(errorMessage) {
      this.errorMessageForModal = errorMessage;
    },
  },
  async serverPrefetch() {
    return await this.getListFromServer();
  },
  async mounted() {
    if (!this.myList || !this.myList.authorized) {
      await this.getList()
    } else {
      if (!this.myList.server) {
        await this.getList()
      }
    }
    this.listAvailable = true;
    this.loading = false;
    this.showCarrousel = true;
    this.retailer = this.productRetailers[0];
    if (this.product.status === this.REGALADO) this.alreadyBought = true;
    if (this.SoldByHelloBB === "Active" && process.env.NODE_ENV === "production")
      hj("event", "sold-by-hellobb");

    let status = this.$route.query.success;
    if(status) {
      if(status.includes("true")) this.$store.commit("setConfirmationMessage", "¡Gracias por tu apoyo! La aportación se ha realizado correctamente.");
      else this.$store.commit("setErrorMessage", "Lo sentimos, ha habido un error al realizar la aportación.");
    }

    this.$i18n.locale = this.myListLanguage;
  },
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.$emit("close");
        this.showAlreadyBoughtContent = false;
      }
    };
    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },
  updated() {
    var iframes = document.querySelectorAll("iframe");
    iframes.forEach(function (ifr) {
      ifr.setAttribute("data-hj-allow-iframe", "");
    });
  },
};
</script>
